import React from 'react';
import {Link} from 'gatsby'
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import {Heroimg, Bussines, Pclients, UrszulaK} from '../components/Images';
import {Media} from "../media/media"

import {SEO, Row, SecTitle, SectionRow, Button, AboutMe, Hero, Layout, Section, Services, Service} from '../components';
import  Contact  from '../components/contact';



const H1 = styled.h1`
  font-weight: 400;
  font-size: 46px;
  color: var(--main-color);
  display: inline-block;
  width: fit-content;
  padding:0.2em;
  margin: 1em auto;
  text-transform: uppercase;
`

const HeroInner = styled.div`
  position: relative;
`

const HeroH1 = styled.div`
  font-family:'DM Serif Display', sans-serif;
  color: var(--font-gold);
  font-size:2.5em;
  position: absolute;
  z-index: 9998;
  top:0;
  bottom:0;
  left:10%;
  height: fit-content;
  width: fit-content;
  margin: auto !important;
  font-weight: 400;
  line-height:1.3em;

  ${Media.sm`
    left:0;
    font-size:1.8em;
    width:100%;
    padding:16px;
  `}
`

const HeroP = styled.p`
  color: var(--bg-color);
  font-size:1.5em;
  position: absolute;
  z-index: 9998;
  top:25%;
  bottom:0;
  left:10%;
  height: fit-content;
  width: fit-content;
  margin: auto !important;
  font-family:
  font-weight: 400;
  line-height:1.3em;

  ${Media.sm`
    top:45%;
    left:0;
    font-size:1.25em;
    width:100%;
    padding:16px;
  `}
`

const TextHome = styled.p`
  max-width:900px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  color: var(--dark-color-light);

  ${Media.sm`
    font-size:1em;
  `}
`;

const TitleService = styled.h1 `
  max-width: 900px;
  font-size: 2em;
  text-align: left;
  display: block;
  padding:1em;
  text-transform: uppercase;
`

const TextService = styled.p`
  min-height:200px;
  max-width:1440px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding:16px;
  line-height: 1.6;
  color: var(--dark-color-light);

  ${Media.sm`
    font-size:0.5m;
  `}
`;


const Home = ({ intl }) => {
  return (
    <Layout>
      <SEO 
      title={intl.formatMessage({ id: 'title' })} 
      description={intl.formatMessage({ id: 'description' })} 
      />
      <Hero id="mainpage" >
        <HeroInner>
          <HeroH1>{intl.formatMessage({ id: 'home.hero.claim' })}</HeroH1>
          <HeroP>{intl.formatMessage({ id: 'home.hero.claim2' })}</HeroP>
          <Heroimg/>
        </HeroInner>  
      </Hero>
      <AboutMe>
        <SectionRow>
          <div className="AboutMeTxt">
            <p className="prefirst">{intl.formatMessage({ id: 'home.hero.prefirst' })}</p>
            <h1>{intl.formatMessage({ id: 'home.hero.first' })}</h1>
            <TextHome>{intl.formatMessage({ id: 'home.text1' })}</TextHome>
            <Link to="/about"><Button>{intl.formatMessage({ id: 'button.about' })}</Button></Link>
          </div>
          <UrszulaK/>
        </SectionRow>
      </AboutMe>
      <Services id="services">
          <SecTitle>{intl.formatMessage({ id: 'home.hero.services' })}</SecTitle>
        <SectionRow>
          <Service>
            <Bussines/>
            <TitleService>{intl.formatMessage({ id: 'bussines.title' })}</TitleService>
            <TextService>{intl.formatMessage({ id: 'home.bussines' })}</TextService>
            <Link to="/bussines"><Button>{intl.formatMessage({ id: 'button.learn' })}</Button></Link>
          </Service>
          <Service>
            <Pclients/>
            <TitleService>{intl.formatMessage({ id: 'private.title' })}</TitleService>
            <TextService>{intl.formatMessage({ id: 'home.p_clients' })}</TextService>
            <Link to="/private-clients"><Button>{intl.formatMessage({ id: 'button.learn' })}</Button></Link>
          </Service>
        </SectionRow>
      </Services>
      {/* <Section id="office">
          <H1>{intl.formatMessage({ id: 'home.hero.second' })}</H1>
          <TextHome>{intl.formatMessage({ id: 'home.text2' })}</TextHome>
      </Section> */}
      <Contact></Contact>
    </Layout>
  );
};

export default injectIntl(Home);
